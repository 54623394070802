<template>
  <div>
    <v-container fluid>
      <div class="main">


        <v-row v-if="isLoading">
          <v-col v-for="item in 4" :key="item" cols="12" lg="3">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-else align="center">
          <v-col cols="12" lg="3">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-5">
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="gifty" size="40" class="rounded-lg">
                      <v-icon color="white">mdi-form-select</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9" class="text-no-wrap">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ total_responses }}
                    </div>
                    Total réponses
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="3">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-5">
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="primary" size="40" class="rounded-lg">
                      <v-icon color="white">mdi-timer-sand</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9" class="text-no-wrap">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ temp_validated_total }}
                    </div>
                    Validation temporaire
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="3">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-5">
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="info" size="40" class="rounded-lg">
                      <v-icon color="white">mdi-checkbox-marked-circle-outline</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9" class="text-no-wrap">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ temp_refused_total }}
                    </div>
                    Validation finale
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="3">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-5">
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="success" size="40" class="rounded-lg">
                      <v-icon color="white">mdi-clipboard-check-outline</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9" class="text-no-wrap">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ (validated_total + refused_total) }}
                    </div>
                    Terminées
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="12">

            <v-tabs background-color="transparent" class="mb-3">
              <v-tab @click="[status='pending',getInvestigations(true)]">
                <v-icon left dense>mdi-timer-sand</v-icon>
                Validation temporaire
              </v-tab>
              <v-tab @click="[status='temp',getInvestigations(true)]">
                <v-icon left dense>mdi-checkbox-marked-circle-outline</v-icon>
                Validation finale
              </v-tab>
              <v-tab @click="[status='completed',getInvestigations(true)]">
                <v-icon left dense>mdi-clipboard-check-outline</v-icon>
                Terminées
              </v-tab>
            </v-tabs>

            <v-card class="rounded-lg shadow" :loading="overlay" :disabled="overlay">

              <v-card-title>
                <div>
                  <v-text-field
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Rechercher..."
                      rounded
                      single-line

                  />
                </div>
                <v-spacer/>

                <v-btn color="grey" text
                       @click="getInvestigations(true)">
                  <v-icon left>mdi-refresh</v-icon>
                  Mettre à jour
                </v-btn>

                <v-btn color="grey" text>
                  <v-icon left>mdi-filter-outline</v-icon>
                  Filtre
                </v-btn>


                <div v-if="status === 'pending'">
                  <v-btn color="red"
                         :disabled="!selected_ids.length"
                         @click="refuseTempDialog = true"
                         class="rounded-lg ml-2 v-btn--active"
                         text>
                    <v-icon left>mdi-close</v-icon>
                    Réfuser
                  </v-btn>

                  <v-btn color="success"
                         :disabled="!selected_ids.length"
                         @click="validateTempDialog = true"
                         class="rounded-lg ml-2 v-btn--active"
                         text>
                    <v-icon left>mdi-check</v-icon>
                    Valider
                  </v-btn>
                </div>

                <div v-if="status === 'temp'">
                  <v-btn color="orange"
                         :disabled="!selected_ids.length"
                         @click="changeStateDialog = true"
                         class="rounded-lg ml-2 v-btn--active"
                         text>
                    <v-icon left>mdi-reload</v-icon>
                    Changer l'état
                  </v-btn>

                  <v-btn color="success"
                         :disabled="!selected_ids.length"
                         @click="acceptDialog = true"
                         class="rounded-lg ml-2 v-btn--active"
                         text>
                    <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>
                    Accepter
                  </v-btn>
                </div>

              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>
                <v-card-text class="pa-0">
                  <v-simple-table v-if="investigations.length">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th v-if="status !== 'completed'">
                          <v-checkbox
                              class="pa-0 ma-0"
                              hide-details
                              color="success"
                              :value="allSelected"
                              @change="toggleSelectAll"
                          ></v-checkbox>
                        </th>
                        <th>Campagne</th>
                        <th class="text-no-wrap">Enquête</th>
                        <th class="text-no-wrap">POS</th>
                        <th>Points</th>
                        <th>Date de réponse</th>
                        <th>Statut</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item ,index) in investigations" :key="index">

                        <td v-if="status !== 'completed'">
                          <v-checkbox class="pa-0 ma-0"
                                      hide-details
                                      color="success"
                                      :value="selected_ids.includes(item.id)"
                                      @change="toggleSelection(item.id)"></v-checkbox>
                        </td>

                        <td class="text-no-wrap">
                          {{ item.investigation.opus_campaign.name['fr'] }}
                        </td>

                        <td class="text-no-wrap">
                          {{ item.investigation.name['fr'] }}
                        </td>


                        <td class="text-no-wrap">
                          {{ item.client_salepoint.pos_name }}
                        </td>

                        <td class="text-no-wrap gifty-text font-weight-medium">
                          {{ item.investigation.winning_points }} Pts
                        </td>

                        <td class="text-no-wrap">
                          {{ item.answered_at }}
                        </td>

                        <td>
                          <v-chip small
                                  :text-color="item.get_status['text_color']"
                                  :class="item.get_status['class']"
                                  class="font-weight-medium">
                            {{ item.get_status['text'] }}
                          </v-chip>
                        </td>

                        <td class="text-no-wrap">

                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs"
                                     @click="$refs.responsesDialog.open(item.investigation)"
                                     v-on="on" color="gifty" icon>
                                <v-icon>mdi-eye-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Voir les réponses</span>
                          </v-tooltip>

                          <v-btn color="success" icon v-if="status === 'pending'"
                                 @click="[validateTempDialog = true,selected_ids = [item.id]]">
                            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                          </v-btn>

                          <v-btn color="error" icon v-if="status === 'pending'"
                                 @click="[refuseTempDialog = true,selected_ids = [item.id]]">
                            <v-icon>mdi-cancel</v-icon>
                          </v-btn>

                          <v-btn color="success" icon v-if="status === 'temp'"
                                 @click="[acceptDialog = true,selected_ids = [item.id]]">
                            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                          </v-btn>

                          <v-btn color="orange" icon v-if="status === 'temp'"
                                 @click="[changeStateDialog = true,selected_ids = [item.id]]">
                            <v-icon>mdi-reload</v-icon>
                          </v-btn>

                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-else>
                    <NoResults @reload="getInvestigations"/>
                  </div>
                </v-card-text>

                <v-divider/>

                <v-card-actions>

                  <div :style="{width: '120px'}">
                    <v-select v-model="perPage"
                              :items="[10,20,30,50,100]"
                              dense
                              hide-details
                              label="Ligne par page"
                              outlined></v-select>
                  </div>

                  <v-spacer/>

                  <v-pagination v-model="pagination.current"
                                :length="pagination.total"
                                circle
                                total-visible="8"
                                @input="getInvestigations"></v-pagination>

                </v-card-actions>

              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <ResponsesDialog ref="responsesDialog"/>

      <v-dialog v-model="validateTempDialog"
                max-width="450"
                persistent>
        <v-card class="text-center">

          <v-card-title>
            <v-spacer/>
            <v-icon @click="validateTempDialog = false">mdi-close</v-icon>
          </v-card-title>

          <v-card-text class="px-10 pb-10">

            <v-icon color="success" size="80">mdi-checkbox-marked-circle-outline</v-icon>

            <h2 class="text--primary mt-3">
              Validation temporaire
            </h2>

            <p class="mt-2 mb-8">
              Êtes-vous sûr(e) de vouloir valider temporairement ces réponses ?
            </p>

            <v-btn class="rounded-lg"
                   block
                   color="success"
                   depressed
                   :loading="btnLoading"
                   @click="changeStatus('temp_validated')">
              <v-icon left>mdi-check-circle-outline</v-icon>
              Valider ({{ selected_ids.length }})
            </v-btn>

          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="refuseTempDialog"
                max-width="450"
                persistent>
        <v-card class="text-center">

          <v-card-title>
            <v-spacer/>
            <v-icon @click="refuseTempDialog = false">mdi-close</v-icon>
          </v-card-title>

          <v-card-text class="px-10 pb-10">

            <v-icon color="error" size="80">mdi-cancel</v-icon>

            <h2 class="text--primary mt-3">
              Réfusation temporaire
            </h2>

            <p class="mt-2 mb-8">
              Êtes-vous sûr(e) de vouloir refuser temporairement ces réponses ?
            </p>

            <v-select v-model="raison_id"
                      :error-messages="errors.raison_id"
                      :items="raisons"
                      dense
                      item-value="id"
                      item-text="name"
                      outlined
                      label="Sélectionnez une raison de refus"
            />

            <v-textarea v-model="remark"
                        :error-messages="errors.remark"
                        dense
                        rows="2"
                        outlined
                        label="Remarque"
                        placeholder="Ajoutez une remarque si nécessaire"
            />

            <v-btn class="rounded-lg" block color="error"
                   :loading="btnLoading"
                   depressed
                   @click="changeStatus('temp_refused')">
              <v-icon left>mdi-check-circle-outline</v-icon>
              Réfuser ({{ selected_ids.length }})
            </v-btn>

          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="changeStateDialog"
                max-width="450"
                persistent>
        <v-card class="text-center">

          <v-card-title>
            <v-spacer/>
            <v-icon @click="changeStateDialog = false">mdi-close</v-icon>
          </v-card-title>

          <v-card-text class="px-10 pb-10">

            <v-icon color="orange" size="80">mdi-reload</v-icon>

            <h2 class="text--primary mt-3">
              Changer l'état
            </h2>

            <p class="mt-2 mb-8">
              Êtes-vous sûr(e) de vouloir changer l'état de ces réponses ?
            </p>

            <v-row class="mb-5">
              <v-col cols="6">
                <v-card outlined @click="check_status = 'temp_validated'"
                        :ripple="false"
                        :class="check_status === 'temp_validated' ? 'checked-card' : '' ">
                  <v-card-text>
                    <div class="d-flex align-center justify-space-between">
                      <v-icon v-if="check_status === 'temp_validated'" color="gifty">mdi-radiobox-marked</v-icon>
                      <v-icon v-else>mdi-circle-outline</v-icon>
                      <span>Validée</span>
                      <v-icon color="success">mdi-checkbox-marked-circle-outline</v-icon>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card outlined @click="check_status = 'temp_refused'"
                        :ripple="false"
                        :class="check_status === 'temp_refused' ? 'checked-card' : '' ">
                  <v-card-text>
                    <div class="d-flex align-center justify-space-between">
                      <v-icon v-if="check_status === 'temp_refused'" color="gifty">mdi-radiobox-marked</v-icon>
                      <v-icon v-else>mdi-circle-outline</v-icon>
                      <span>Refusée</span>
                      <v-icon color="red">mdi-cancel</v-icon>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-btn class="rounded-lg white--text"
                   block
                   color="orange"
                   :disabled="!check_status"
                   :loading="btnLoading"
                   depressed
                   @click="changeState">
              <v-icon left>mdi-check-circle-outline</v-icon>
              Changer ({{ selected_ids.length }})
            </v-btn>

          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="acceptDialog"
                max-width="450"
                persistent>
        <v-card class="text-center">

          <v-card-title>
            <v-spacer/>
            <v-icon @click="acceptDialog = false">mdi-close</v-icon>
          </v-card-title>

          <v-card-text class="px-10 pb-10">

            <v-icon color="success" size="80">mdi-checkbox-marked-circle-outline</v-icon>

            <h2 class="text--primary mt-3">
              Accepter
            </h2>

            <p class="mt-2 mb-8">
              Êtes-vous sûr(e) de vouloir accepter ces réponses ?
            </p>

            <v-btn class="rounded-lg"
                   block
                   color="success"
                   depressed
                   :loading="btnLoading"
                   @click="accept">
              <v-icon left>mdi-check-circle-outline</v-icon>
              Accepter ({{ selected_ids.length }})
            </v-btn>

          </v-card-text>
        </v-card>
      </v-dialog>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import ResponsesDialog from "./components/ResponsesDialog";

export default {
  components: {NoResults, ResponsesDialog},
  data() {
    return {
      keyword: '',
      status: 'pending',
      overlay: false,
      refuseTempDialog: false,
      validateTempDialog: false,
      changeStateDialog: false,
      acceptDialog: false,
      isLoading: false,
      investigations: [],
      raisons: [],

      check_status: "",
      btnLoading: false,
      selected_ids: [],
      remark: "",
      raison_id: "",
      errors: {},

      temp_validated_total: 0,
      temp_refused_total: 0,
      refused_total: 0,
      validated_total: 0,
      total_responses: 0,

      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },
    };
  },
  computed: {
    allSelected() {
      return this.investigations.every((item) => this.selected_ids.includes(item.id));
    },
  },
  methods: {
    changeStatus(status) {
      this.btnLoading = true
      HTTP.post('/v1/opus/backoffice/investigations/change/status', {
        status: status,
        investigation_salepoint_ids: this.selected_ids,
        remark: this.remark,
        raison_id: this.raison_id,
      }).then(() => {
        this.btnLoading = false
        this.refuseTempDialog = false
        this.validateTempDialog = false
        this.$successMessage = "Opération réussi !"
        this.getInvestigations(true)

        //Empty data
        this.selected_ids = []
        this.remark = ""
        this.raison_id = ""
      }).catch(err => {
        this.btnLoading = false
        this.errors = err.response.data.errors
        console.log(err)
      })
    },
    changeState() {
      this.btnLoading = true
      HTTP.post('/v1/opus/backoffice/investigations/change/temp/status', {
        status: this.check_status,
        investigation_salepoint_ids: this.selected_ids,
      }).then(() => {
        this.btnLoading = false
        this.changeStateDialog = false
        this.$successMessage = "Opération réussi !"
        this.getInvestigations(true)

        //Empty data
        this.selected_ids = []
        this.check_status = ""
      }).catch(err => {
        this.btnLoading = false
        console.log(err)
      })
    },
    accept() {
      this.btnLoading = true
      HTTP.post('/v1/opus/backoffice/investigations/accept', {
        investigation_salepoint_ids: this.selected_ids,
      }).then(() => {
        this.btnLoading = false
        this.acceptDialog = false
        this.$successMessage = "Opération réussi !"
        this.getInvestigations(true)

        //Empty data
        this.selected_ids = []
      }).catch(err => {
        this.btnLoading = false
        console.log(err)
      })
    },
    toggleSelection(itemId) {
      const ids = this.selected_ids;
      const index = ids.indexOf(itemId);

      if (index === -1) {
        ids.push(itemId);
      } else {
        ids.splice(index, 1);
      }
    },
    toggleSelectAll() {
      const ids = this.investigations.map((investigation) => investigation.id);
      const selectedIds = this.selected_ids;

      if (this.allSelected) {
        this.selected_ids = selectedIds.filter(
            (id) => !ids.includes(id)
        );
      } else {
        this.selected_ids = [...new Set([...selectedIds, ...ids])];
      }
    },
    getInvestigations(overlay = false) {

      if (overlay) {
        this.overlay = true
      } else {
        this.isLoading = true
      }

      HTTP.get('/v1/opus/backoffice/investigations?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
          keyword: this.keyword,
          status: this.status,
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;
        this.investigations = res.data.data.data

        this.temp_validated_total = res.data.temp_validated_total
        this.total_responses = res.data.total_responses
        this.temp_refused_total = res.data.temp_refused_total
        this.validated_total = res.data.validated_total
        this.refused_total = res.data.refused_total

        this.isLoading = false
        this.overlay = false
      }).catch(err => {
        this.isLoading = false
        this.overlay = false
        console.log(err)
      })
    },
    getRaisons() {
      HTTP.get('/v1/opus/backoffice/raisons').then(res => {
        this.raisons = res.data.data
      }).catch(err => {
        console.log(err)
      })
    },
  },
  created() {
    this.getInvestigations();
    this.getRaisons();
  },
  watch: {
    perPage() {
      this.pagination.current = 1
      this.getInvestigations();
    },
    keyword() {
      this.getInvestigations();
    },
  }
};
</script>

<style scoped>
.cursor-zomm-in {
  cursor: zoom-in;
}

.bg-subtle-warning {
  background: rgba(255, 171, 2, 0.15) !important;
}

.bg-subtle-success {
  background: rgba(121, 215, 15, 0.15) !important;
}

.bg-subtle-error {
  background: rgba(255, 36, 66, 0.15) !important;
}

.checked-card {
  background-color: rgba(51, 51, 51, 0.08);
}
</style>
