<template>
  <v-dialog max-width="1000"
            scrollable
            v-model="dialog">

    <v-card>
      <v-card-title>
        Réponses
        <v-spacer/>
        <v-icon @click="dialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>

      <v-card-text class="pa-8">

        <v-card outlined class="rounded-lg mb-3" v-for="(task,i) in investigation.tasks" :key="i">


          <v-card-text>
            <v-chip text-color="gifty"
                    label
                    class="bg-subtle-gifty"
            >
              Tâche N° : {{ i + 1 }}
            </v-chip>
          </v-card-text>

          <v-divider/>

          <v-card-text class="grey lighten-5">
            <v-row>
              <v-col cols="6" class="d-flex align-center">
                <v-icon class="mr-2">mdi-book-open</v-icon>
                <h4 class="grey--text text--darken-3">Intitulé</h4>
              </v-col>
              <v-col cols="6" class="d-flex align-center">
                <v-icon class="mr-2">mdi-message-reply-text</v-icon>
                <h4 class="grey--text text--darken-3">Réponse</h4>
              </v-col>
            </v-row>
          </v-card-text>


          <v-divider/>

          <v-card-text>
            <v-row>

              <v-col cols="6">
                <p>
                  {{ task.question.fr }}
                </p>
                <div v-if="task.question_type.key === 'image'">
                  <v-row>
                    <v-col cols="3">
                      <v-img width="100%"
                             height="100%"
                             @click="[lightboxImage = [{src: FILE_URL + task.file}],visible = true]"
                             class="rounded-lg grey lighten-4 cursor-zoom"
                             :src="FILE_URL + task.file"></v-img>
                    </v-col>
                  </v-row>
                </div>
              </v-col>

              <v-col cols="6">

                <v-alert color="info" text :icon="task.answer_type.icon" dense>
                  {{ task.answer_type.description.fr }}
                </v-alert>

                <div v-if="task.answer_type.key === 'multiple_images'">
                  <v-row>
                    <v-col cols="3" v-for="(image,imgIndex) in task.response?.response?.data" :key="imgIndex">
                      <v-img v-if="image"
                             width="100%"
                             height="100%"
                             @click="showLightbox(imgIndex,task.response?.response?.data)"
                             class="rounded-lg grey lighten-4 cursor-zoom"
                             :src="FILE_URL + image"></v-img>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="task.answer_type.key === 'image'">
                  <v-row>
                    <v-col cols="3">
                      <v-img width="100%"
                             height="100%"
                             @click="[lightboxImage = [{src: FILE_URL + task.response?.response?.data}],visible = true]"
                             class="rounded-lg grey lighten-4 cursor-zoom"
                             :src="FILE_URL + task.response?.response?.data"></v-img>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="task.answer_type.key === 'short_paragraph'
                 || task.answer_type.key === 'paragraph'
                 || task.answer_type.key === 'date'
                 || task.answer_type.key === 'time'">
                  {{ task.response?.response?.data }}
                </div>


                <div v-if="task.answer_type.key === 'file'">
                  <!--TODO-->
                </div>

                <div v-if="task.answer_type.key === 'audio'">
                  <!--TODO-->
                </div>

                <div v-if="task.answer_type.key === 'video'">
                  <!--TODO-->
                </div>

                <div v-if="task.answer_type.key === 'checkbox' || task.answer_type.key === 'radio'">

                  <div v-for="(option,optionIndex) in task.task_question_options"
                       class="table-border pa-2 mb-1 rounded d-flex align-center justify-space-between"
                       :key="optionIndex">
                    {{ option.option?.option?.fr }}

                    <div v-if="task.answer_type.key === 'checkbox'">
                      <v-icon color="success" v-if="task.response?.response?.data.includes(option.id)">
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else>
                        mdi-checkbox-blank-outline
                      </v-icon>
                    </div>

                    <div v-if="task.answer_type.key === 'radio'">
                      <v-icon color="success" v-if="task.response?.response?.data === option.id">
                        mdi-radiobox-marked
                      </v-icon>
                      <v-icon v-else>
                        mdi-radiobox-blank
                      </v-icon>
                    </div>

                  </div>

                </div>

              </v-col>

            </v-row>
          </v-card-text>

        </v-card>

      </v-card-text>

    </v-card>

    <vue-easy-lightbox escDisabled
                       moveDisabled
                       :visible="visible"
                       :imgs="lightboxImage"
                       :index="index"
                       @hide="visible = false"></vue-easy-lightbox>

  </v-dialog>

</template>

<script>
export default {
  data() {
    return {
      overlay: false,
      isLoading: false,
      dialog: false,
      investigation: {},

      /**Lightbox**/
      FILE_URL: process.env.VUE_APP_FILE_URL,
      lightboxImage: "",
      visible: false,
      index: 0,
    }
  },
  methods: {
    open(investigation) {
      this.investigation = investigation
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    showLightbox(index, imgs) {
      if (imgs && imgs.length) {
        this.lightboxImage = imgs.map((img) => {
          return {src: this.FILE_URL + img}
        });
        this.index = index;
        this.visible = true;
      }
    },
  }
}
</script>

<style scoped>
.bg-subtle-gifty {
  background-color: rgba(214, 12, 86, 0.15) !important;
}
</style>